<template lang="pug">
div
  .reg-notification-list
    .wuwow-card(v-for='notification in notifications' :key='notification.id')
      .wuwow-card-head
        h4.h4-notification-title {{ notification.title }}
      .wuwow-card-body
        .are-notification-content(v-html='notification.subContent')
      .wuwow-card-footer
        router-link.btn.btn-outline-primary(:to="{ path:'/dojo/notification/bulletin/' + notification.id}") {{ $t('more') }}
        //- no-close-on-backdrop
  //- b-modal( id="isNotNowPlatform" size="xl" centered hide-footer :hide-header="true")
  //-   .isNotNowPlatform
  //-     h3.text-danger 已偵測到本組帳密為 WUWOW 帳戶，是否帶您進入 WUWOW ?
  //-     .flex.justify-betwen.items-center
  //-       a.btn.btn-primary(href='javascript:;' @click='logout') 再次登入
  //-       a.btn.btn-primary(:href="loginUrl") 登入WUWOW
  //- summer-camp-modal
  //- new-line-modal
</template>

<script>
// WUWOW_EXTERNAL_URL
// 註解起來的後續沒有問題後全部砍掉
import i18n from './lang';
import api, { NotificationType, Platform } from '@lioshutan/api-package';
import lioshutanApi from '@/api';
// import { mapState, mapMutations } from 'vuex';
import { mapState } from 'vuex';

// import summerCampModal from '@/views/dojo/summerCamp/summerCampModal.vue';

// for modal
// import { MODAL } from '@/store';
// import { SET_IS_OPEN } from '@/store/modules/modal';

// import { uuid as NEW_LINE_UUID } from '@/views/dojo/newLineModal/index.vue';

// import cookie from 'js-cookie';

// component
// import newLineModal from '@/views/dojo/newLineModal/index.vue';

export default {
  name: 'Home',

  i18n,

  components: {
    // summerCampModal,
    // newLineModal,
  },

  data() {
    return {
      notifications: [],
      hours24NotShow: '' || localStorage.getItem('hours24NotShow'),
      todayShowOnce: false || JSON.parse(localStorage.getItem('todayShowOnce')),
      // for modal
      // modal: {
      //   NEW_LINE_UUID,
      // },
    };
  },

  computed: {
    ...mapState('auth', [
      'token',
    ]),
    ...mapState('summerCamp', ['summerRewardEventInfo']),
    ...mapState({
      vipProfileComplete: (state) => state.vipAccount.vipAccountCompleteProfile,
    }),
  },
  async created() {
    const response = await api
      .setDNS(process.env.VUE_APP_BASE_API)
      .notification(this.token)
      .getNotification({
        type: NotificationType.NOTIFY,
        page: 1,
        perPage: 10,
        // TODO: 後續要改成ＪＲ
        platform: Platform.WUWOW_JUNIOR,
        needContent: true,
        needSubContent: true,
      });
    this.notifications = response.data.notifications;

    // 取得學生 line 裝置綁定狀況
    await this.getVipAccountCompleteProfile();
    // this.setIsOpen({ target: this.modal.NEW_LINE_UUID, status: true });

    // // 跟據 line 裝置綁定狀況及 newLineModel cookie
    // this.setNewLineModel();
  },

  methods: {
    // for modal
    // ...mapMutations(MODAL, [SET_IS_OPEN]),

    async getVipAccountCompleteProfile() {
      // 取得學生完整資料，並存回 Vuex
      const completeProfileResult = await lioshutanApi.vip.getCompleteProfile();
      this.$store.dispatch('vipAccount/addVipAccountCompleteProfile', completeProfileResult.data.profile_base);
    },
    // async getVipPointSuggest() {
    //   const res = await lioshutanApi.vip.getVipPointSuggest();
    //   this.$store.dispatch('vipAccount/setPlatform', res.data.platform);
    //   if (res.data.platform) {
    //     const { now } = res.data.platform;
    //     if (now.id !== 2) {
    //       this.$bvModal.show('isNotNowPlatform');
    //     }
    //   }
    // },
    // async logout() {
    //   logout();
    // },
    // openSummerCampModal() {
    //   this.$bvModal.show('summerCampModal');
    //   this.setTodayShowOnce(true);
    // },
    // closeSummerCampModal() {
    //   this.$bvModal.hide('summerCamp');
    // },
    // setTodayShowOnce(data) {
    //   localStorage.setItem('todayShowOnce', JSON.stringify(data));
    //   this.todayShowOnce = data;
    // },
    // summerNotice() {
    //   const today = moment().format('YYYY-MM-DD');
    //   const passed = this.summerRewardEventInfo.passedAt !== '';
    //   const failed = this.summerRewardEventInfo.failedAt !== '';
    //   const customizeClassesCompletionStatus = this.summerRewardEventInfo.customizeClassesCompletionStatus === 'running';
    //   if (this.todayShowOnce) {
    //     // 如果今天顯示過就不要在顯示(登出除外)
    //     return;
    //   }
    //   if (customizeClassesCompletionStatus && this.hours24NotShow === today) {
    //     // 如果正在挑戰 40天任務，但勾選了不在顯示，就不要談窗
    //     return;
    //   }
    //   if ([passed, failed, customizeClassesCompletionStatus].some(item => item)) {
    //     this.openSummerCampModal();
    //   }
    // },
    // setNewLineModel() {
    //   const noAdCookie = cookie.get('newLineModal');

    //   if (noAdCookie || this.vipProfileComplete.social_media.bind.line) {
    //     this.setIsOpen({ target: this.modal.NEW_LINE_UUID, status: false });
    //   } else {
    //     this.setIsOpen({ target: this.modal.NEW_LINE_UUID, status: true });
    //   }
    // },
  },
};
</script>
<style lang="scss">
@import '@/views/dojo/home';
.isNotNowPlatform {
  text-align: center;
  h3 {
    font-size: 24px;
    margin-bottom: 40px;
  }
  a {
    margin: 0 12px;
  }
}
</style>
