var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "reg-notification-list"
  }, _vm._l(_vm.notifications, function (notification) {
    return _c('div', {
      key: notification.id,
      staticClass: "wuwow-card"
    }, [_c('div', {
      staticClass: "wuwow-card-head"
    }, [_c('h4', {
      staticClass: "h4-notification-title"
    }, [_vm._v(_vm._s(notification.title))])]), _c('div', {
      staticClass: "wuwow-card-body"
    }, [_c('div', {
      staticClass: "are-notification-content",
      domProps: {
        "innerHTML": _vm._s(notification.subContent)
      }
    })]), _c('div', {
      staticClass: "wuwow-card-footer"
    }, [_c('router-link', {
      staticClass: "btn btn-outline-primary",
      attrs: {
        "to": {
          path: '/dojo/notification/bulletin/' + notification.id
        }
      }
    }, [_vm._v(_vm._s(_vm.$t('more')))])], 1)]);
  }), 0)]);

}
var staticRenderFns = []

export { render, staticRenderFns }