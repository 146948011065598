export default {
  messages: {
    tw: {
      more: '查看更多',
    },
    en: {
      more: 'More',
    },
  },
};
